import React, { useState } from 'react';

import CoinGeko from './dataProviders/CoinGeko';
import AddForm from './components/AddForm/AddForm';
import CurrencyList from './components/CurrencyList/CurrencyList';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Container from 'react-bootstrap/Container';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Cookies from 'js-cookie'

import './App.css';

class App extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            url: props.url,
            login: props.login,
            auth_key: props.auth_key,
            items: [],
            coins: [],
            rates: {
                usd: 0
            }
        }
        
        CoinGeko.getCurrencies().then((res) => {
            this.setState({coins: res})
        });

        this.handleAddRecord = this.handleAddRecord.bind(this);
        this.updateFile = this.updateFile.bind(this);  
    }

    componentDidMount(){
        this.getData()
        this.getNbpRates()
    }

    render() {
        return (
            <Container className="p-3 main-container">
                <div className="logout-container">
                    {/* <Button onClick={this.props.handleLogout} className="select-columns">Kolumny</Button> */}
                    <Button onClick={this.props.handleLogout} className="logout-btn btn-danger">Wyloguj się</Button>
                </div>
                <div className="accordion-container">
                    <Accordion>
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                    Dodaj zakup
                                </Accordion.Toggle>
                            </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                <Jumbotron>
                                    <AddForm coins={this.state.coins} handleAddRecord={this.handleAddRecord}/>
                                </Jumbotron>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                    
                </div>
                {/* <div className="list-container"> */}
                    <CurrencyList items={this.state.items} rates={this.state.rates} />
                {/* </div> */}
            </Container>
        );
    }

    handleAddRecord(event){
        event.preventDefault();

        let req = {}
        const amount = parseFloat(event.target[0].value);
        const currency = event.target[1].value;
        req[currency] = currency;

        CoinGeko.getDetails(req).then((res) => {
            if(amount != null && currency != null){
                this.updateFile({amount: amount, currency_id: currency, price: res[currency].price})
            }
        });
    }

    updateFile(data){
        fetch(this.state.url + 'api/web/v1/mcf/add', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'Bearer ' + Cookies.get('auth_key'),
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.status >= 200 && response.status < 300) {
                this.getData()
                return true;
            } else {
                console.log('Something happened wrong');
            }
        }).catch(err => {console.log(err)});
    }

    getData(){
        fetch(this.state.url + "api/web/v1/mcf/index", {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + Cookies.get('auth_key'),
                'Content-Type': 'application/json',
            }
        })
        .then(res => res.json())
        .then(
            (result) => {
                console.log(result)
                if(typeof result.code == 'undefined'){
                    let ret = []
                    let data = result.map((item) => {
                        return {currency: item.currency_id, amount: item.amount, mean: item.mean};
                    });
                    data.forEach( function( value ) {
                        ret[value.currency] = value;
                    });
                    this.setState({items: ret})
                }
            },
            (error) => {
                console.log(error)
            }
        )
    }

    getNbpRates(){
        fetch("https://api.nbp.pl/api/exchangerates/rates/a/usd/", {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
            }
        })
        .then(res => res.json())
        .then(
            (result) => {
                let rates = this.state.rates;
                rates['usd'] = result.rates[0].mid
                this.setState({rates: rates})
            },
            (error) => {
                console.log(error)
            }
        )
    }
}

export default App;