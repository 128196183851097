import React from 'react';
import './CurrencyList.css';
import CoinGeko from '../../dataProviders/CoinGeko';
import { Link } from 'react-router-dom';
import { FaArrowAltCircleLeft } from 'react-icons/fa';

class CurrencyDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: '',
            name: '',
            details: '',
            history: '',
        }
    }

    async componentDidMount() {
        try {

            let id = this.props.match.params.name;
            let data = {vs_currency: 'usd', days: 10}
            data = Object.entries(data).map(pair => pair.map(encodeURIComponent).join('=')).join('&');

            const details = await fetch(`https://api.coingecko.com/api/v3/coins/`+id);
            const history = await fetch(`https://api.coingecko.com/api/v3/coins/`+id+`/market_chart?` + data);
            const detailsJson = await details.json();
            const historyJson = await history.json();

            this.setState({ id: id })
            this.setState({ details: detailsJson });
            this.setState({ history: historyJson });
            this.setState({ name: detailsJson.name });
            
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        return (
            <div>
                <div className="header-buttons">
                    <Link to={{ pathname: '/' }}>
                        <FaArrowAltCircleLeft />
                    </Link>
                </div>
                <div className="content">
                    <h1>{this.state.name}</h1>
                </div>
            </div>
        );
    }
}

export default CurrencyDetails;