import React from "react";
import App from './App';
import Login from './components/Login/Login';
import CurrencyDetails from './components/CurrencyDetails/CurrencyDetails';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Redirect, useParams } from "react-router-dom";
import Cookies from 'js-cookie'

class Main extends React.Component {

    apiUrl = "https://michalbronowski.dev/";
    // apiUrl = "http://localhost:80/startowa/";

    constructor() {
        super();
        this.state = {
            login: false,
            auth_key: '',
        }
        this.handleLogin = this.handleLogin.bind(this);        
        this.handleLogout = this.handleLogout.bind(this);        
    }

    render() {
        
        if(this.state.auth_key == '' && !!Cookies.get('auth_key')){
            this.setState({login: true});
            this.setState({auth_key: Cookies.get('auth_key')});
        }

        return (
            <Router>
                <Switch>
                    <Route exact path="/" component={
                        this.state.login
                        ? () => <App handleLogout={this.handleLogout} url={this.apiUrl}/>
                        : () => <Login handleLogin={this.handleLogin} url={this.apiUrl}/>
                    } />
                    <Route exact path="/details/:name" component={
                        this.state.login
                        ? ({ match }) => <CurrencyDetails match={match} handleLogout={this.handleLogout}/>
                        : () => <Login handleLogin={this.handleLogin} url={this.apiUrl}/>
                    } />
                </Switch>
            </Router>
        );
    }

    handleLogin(event) {

        event.preventDefault();

        const data = {
            username: event.target[0].value,
            password: event.target[1].value,
        }

        const expires = 60 * 60 * 1000
        const inOneHour = new Date(new Date().getTime() + expires)

        fetch(this.apiUrl + 'api/web/v1/mcf/login', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            },
        })
        .then(res => res.json())
        .then(
            (result) => {
                console.log(result)
                if(typeof result.code == 'undefined' && typeof result.authorizationToken != 'undefined'){
                    this.setState({login: true})
                    this.setState({auth_key: result.authorizationToken.token})
                    Cookies.set('auth_key', result.authorizationToken.token, { expires: inOneHour })
                } else {
                    console.log('error', result);
                }
            },
            (error) => {
                console.log('error', error)
            }
        );
    }

    handleLogout(){
        this.setState({login: false});
        this.setState({auth_key: ''});
        Cookies.remove('auth_key')
        window.location.reload();
    }
}

export default Main;