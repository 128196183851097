import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "./login.css";

class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            login: false,
            auth_key: '',
        }
    }

    render() {
        return (
            <div className="Login">
                <Form onSubmit={this.props.handleLogin}>
                    <Form.Group size="lg" controlId="username">
                        <Form.Label>Login</Form.Label>
                        <Form.Control
                            autoFocus
                            type="text"
                            value={this.state.username}
                            onChange={(e) => this.setState({username: e.target.value})}
                        />
                    </Form.Group>
                    <Form.Group size="lg" controlId="password">
                        <Form.Label>Hasło</Form.Label>
                        <Form.Control
                            type="password"
                            value={this.state.password}
                            onChange={(e) => this.setState({password: e.target.value})}
                        />
                    </Form.Group>
                    <Button block size="lg" type="submit" className="submitBtn" disabled={!this.validateForm()}>
                        Login
                    </Button>
                </Form>
            </div>
        );
    }

    validateForm() {
        return this.state.username.length > 0 && this.state.password.length > 0;
    }
}

export default Login;