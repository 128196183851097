import React from 'react';
import { Table } from 'react-bootstrap'
import CoinGeko from '../../dataProviders/CoinGeko';

import './CurrencyList.css';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { FaSortUp, FaSortDown } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie'

class CurrencyList extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            items: props.items,
            rates: props.rates,
            sumUSD: 0,
            sort: '',
            sortDir: '',
            interval: ''
        }

        this.createRows = this.createRows.bind(this);
        this.watchForChanges = this.watchForChanges.bind(this);
        this.handleSort = this.handleSort.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        return {
            items: props.items,
            rates: props.rates
        };
    }

    async componentDidMount() {

        this.watchForChanges();
        setTimeout(() => this.watchForChanges, 5000);
        setInterval(() => {
            this.watchForChanges()
        }, 10 * 1000);
    }

    render() {

        const expires = 60 * 60 * 1000
        const inOneHour = new Date(new Date().getTime() + expires)

        let title = 'Sum:\xA0$' + (this.state.sumUSD.toFixed(2) * this.state.rates['usd']).toFixed(2).toLocaleString();
        // title += (typeof this.state.items['bitcoin'] != 'undefined' && typeof this.state.items['bitcoin'].price != 'undefined') ? 
        //     '\xA0|\xA0BTC:\xA0' + this.state.items['bitcoin'].price.toLocaleString() + '\xA0|\xA0' : ''

        if(title.length > 25 && document.title !== title){
            document.title = title
            window.clearTimeout(Cookies.get('interval'))
            let interval = window.setInterval(function() {
                // Take the first character, and make it the last character
                document.title = document.title.substring(1) + document.title[0];
            }, 1000);
            Cookies.set('interval', interval, { expires: inOneHour })
        }

        return (
            <div>
                <div>
                    <Accordion>
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                    Podsumowanie
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Suma USD</th>
                                                <th>Suma PLN</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>${this.state.sumUSD.toFixed(2)}</td>
                                                <td>{(this.state.sumUSD.toFixed(2) * this.state.rates['usd']).toFixed(2)} zł</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                </div>

                <div className="table-container">
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th onClick={() => this.setSort('currency')}>
                                    {(this.state.sortDir === '' || this.state.sort !== 'currency') ?
                                        '' : (this.state.sortDir === 'asc' ?  <FaSortUp /> : <FaSortDown />)}
                                    Nazwa
                                </th>
                                <th onClick={() => this.setSort('amount')}>
                                    {(this.state.sortDir === '' || this.state.sort !== 'amount') ?
                                        '' : (this.state.sortDir === 'asc' ? <FaSortUp /> : <FaSortDown />)}
                                    Posiadana ilość
                                </th>
                                <th onClick={() => this.setSort('priceUSD')}>
                                    {(this.state.sortDir === '' || this.state.sort !== 'priceUSD') ?
                                        '' : (this.state.sortDir === 'asc' ? <FaSortUp /> : <FaSortDown />)}
                                    Cena USD
                                </th>
                                <th onClick={() => this.setSort('priceOTH')}>
                                    {(this.state.sortDir === '' || this.state.sort !== 'priceOTH') ?
                                        '' : (this.state.sortDir === 'asc' ? <FaSortUp /> : <FaSortDown />)}
                                    Cena PLN
                                </th>
                                <th onClick={() => this.setSort('sumUSD')}>
                                    {(this.state.sortDir === '' || this.state.sort !== 'sumUSD') ?
                                        '' : (this.state.sortDir === 'asc' ? <FaSortUp /> : <FaSortDown />)}
                                    Wartość USD
                                </th>
                                <th onClick={() => this.setSort('sumOTH')}>
                                    {(this.state.sortDir === '' || this.state.sort !== 'sumOTH') ?
                                        '' : (this.state.sortDir === 'asc' ? <FaSortUp /> : <FaSortDown />)}
                                    Wartość PLN
                                </th>
                                <th onClick={() => this.setSort('meanPrice')}>
                                    {(this.state.sortDir === '' || this.state.sort !== 'meanPrice') ?
                                        '' : (this.state.sortDir === 'asc' ? <FaSortUp /> : <FaSortDown />)}
                                    Średnia cena kupna
                                </th>
                                <th onClick={() => this.setSort('rewardUSD')}>
                                    {(this.state.sortDir === '' || this.state.sort !== 'rewardUSD') ?
                                        '' : (this.state.sortDir === 'asc' ? <FaSortUp /> : <FaSortDown />)}
                                    Zysk/strata USD
                                </th>
                                <th onClick={() => this.setSort('rewardPLN')}>
                                    {(this.state.sortDir === '' || this.state.sort !== 'rewardPLN') ?
                                        '' : (this.state.sortDir === 'asc' ? <FaSortUp /> : <FaSortDown />)}
                                    Zysk/strata PLN
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.createRows()}
                        </tbody>
                    </Table>
                </div>

            </div>
        );
    }

    watchForChanges() {
        CoinGeko.getDetails(this.state.items).then((res) => {

            let sum = 0;
            let items = this.state.items

            Object.keys(res).forEach((name) => {
                if (typeof items[name] != 'undefined') {
                    items[name]['currency'] = res[name].name
                    items[name]['price'] = res[name].price
                }
            })

            Object.keys(this.state.items).forEach((sumItem) => {
                let model = this.state.items[sumItem]
                sum += parseFloat((model.amount != null && model.price != null) ? model.amount * model.price : 0)
            })

            this.setState({ items: items });
            this.setState({ sumUSD: sum });
        });
    }

    createRows() {
        let array = []
        let items = this.handleSort(this.state.items, this.state.sort)
        Object.keys(items).forEach((element) => {
            let model = items[element]
            console.log(model)
            let rate = (model.price != null) ? model.price : 0
            let sum = (model.amount != null && model.price != null) ? model.amount * model.price : 0

            let meanCond = model.mean != null && model.mean != 0
            let mean = meanCond ? model.mean * model.amount : 0

            if(model.amount > 0){

                let ratePln = rate * this.state.rates['usd'] > 1 ? (rate * this.state.rates['usd']).toFixed(2) : (rate * this.state.rates['usd']).toFixed(7)
                if(model.mean != 0 && !Number.isNaN(model.mean)){
                    model.mean = model.mean > 1 ? parseFloat(model.mean).toFixed(2) : parseFloat(model.mean).toFixed(7)
                }

                array.push(
                    <tr key={element}>
                        <td><Link to={{pathname: '/details/'+element, query: {name: element}}}>{model.currency}</Link></td>
                        <td>{model.amount}</td>
                        <td>${rate}</td>
                        <td>{ratePln} zł</td>
                        <td>${sum.toFixed(2)}</td>
                        <td>{(sum * this.state.rates['usd']).toFixed(2)} zł</td>
                        <td>{meanCond ? '$'+model.mean : '-'}</td>
                        <td>{meanCond ? '$'+(sum - mean).toFixed(2) : sum.toFixed(2)}</td>
                        <td>{meanCond ? ((sum - mean) * this.state.rates['usd']).toFixed(2)+' zł' : (sum * this.state.rates['usd']).toFixed(2)}</td>
                    </tr>
                )
            }
        })
        return array;
    }

    setSort(attr) {
        if (this.state.sortDir === '') {
            this.setState({ sortDir: 'asc' });
        } else if (this.state.sort === attr) {
            let dir = this.state.sortDir === 'desc' ? 'asc' : 'desc';
            this.setState({ sortDir: dir });
        } else {
            this.setState({ sort: attr });
        }
    }

    handleSort(input, sortKey) {

        if (sortKey === '') return input

        let data = [];
        let tuples = [];
        const that = this

        for (let key in input) tuples.push([key, input[key]]);

        if (sortKey === 'currency') {
            tuples.sort(function (a, b) {
                if (that.state.sortDir === 'asc') {
                    return a[1][sortKey].localeCompare(b[1][sortKey]);
                } else {
                    return b[1][sortKey].localeCompare(a[1][sortKey]);
                }
            });
        } else if (sortKey === 'amount') {
            tuples.sort(function (a, b) {
                let aVal = parseFloat(a[1][sortKey]);
                let bVal = parseFloat(b[1][sortKey]);
                if (that.state.sortDir === 'desc') {
                    return aVal > bVal ? 1 : aVal < bVal ? -1 : 0
                } else {
                    return aVal < bVal ? 1 : aVal > bVal ? -1 : 0
                }
            });
        } else if (sortKey === 'priceUSD' || sortKey === 'priceOTH') {
            tuples.sort(function (a, b) {
                let aVal = parseFloat(a[1]['price']);
                let bVal = parseFloat(b[1]['price']);
                if (that.state.sortDir === 'desc') {
                    return aVal > bVal ? 1 : aVal < bVal ? -1 : 0
                } else {
                    return aVal < bVal ? 1 : aVal > bVal ? -1 : 0
                }
            });
        } else if (sortKey === 'sumUSD' || sortKey === 'sumOTH') {
            tuples.sort(function (a, b) {
                let aVal = parseFloat(a[1]['price'] * a[1]['amount']);
                let bVal = parseFloat(b[1]['price'] * b[1]['amount']);
                if (that.state.sortDir === 'desc') {
                    return aVal > bVal ? 1 : aVal < bVal ? -1 : 0
                } else {
                    return aVal < bVal ? 1 : aVal > bVal ? -1 : 0
                }
            });
        } else if (sortKey === 'meanPrice') {
            tuples.sort(function (a, b) {
                let aVal = parseFloat(a[1]['mean']);
                let bVal = parseFloat(b[1]['mean']);
                if (that.state.sortDir === 'desc') {
                    return aVal > bVal ? 1 : aVal < bVal ? -1 : 0
                } else {
                    return aVal < bVal ? 1 : aVal > bVal ? -1 : 0
                }
            });
        } else if (sortKey === 'rewardUSD' || sortKey === 'rewardPLN') {
            tuples.sort(function (a, b) {

                let sumA = a[1]['price'] * a[1]['amount']
                let sumB = b[1]['price'] * b[1]['amount']
                let meanA = a[1]['mean'] * a[1]['amount']
                let meanB = b[1]['mean'] * b[1]['amount']

                let aVal = parseFloat(sumA - meanA);
                let bVal = parseFloat(sumB - meanB);

                if (that.state.sortDir === 'desc') {
                    return aVal > bVal ? 1 : aVal < bVal ? -1 : 0
                } else {
                    return aVal < bVal ? 1 : aVal > bVal ? -1 : 0
                }
            });
        }
        
        
        for (let i = 0; i < tuples.length; i++) {
            data[tuples[i][0]] = tuples[i][1];
        }

        return data
    }
}

export default CurrencyList;